import {SmartComponent} from "smart-component-js";


class ExtendedWarrantyComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element

        this.step1 = this.element.querySelector('.step1');
        this.step2 = this.element.querySelector('.step2');
        this.step3 = this.element.querySelector('.step3');
        this.next1 = this.element.querySelector('#next1');
        this.next2 = this.element.querySelector('#next2');
        this.prev2 = this.element.querySelector('#prev2');
        this.prev3 = this.element.querySelector('#prev3');
        this.tabs = this.element.querySelectorAll('.tab');
        this.submit = this.element.querySelector('.submit-warranty');

        this.inputFile = this.element.querySelector('#myFile');
        this.fileNameDisplay = this.element.querySelector('#fileNameDisplay');

        this.tireSelection = this.element.querySelector('#tireWarranty');
        this.frontForm = this.element.querySelector('.front-tires-form');
        this.rearForm = this.element.querySelector('.rear-tires-form');
        this.frontFormInputs = this.element.querySelectorAll('.front-tires-form input[required], .front-tires-form select[required]');
        this.rearFormInputs = this.element.querySelectorAll('.rear-tires-form input[required], .rear-tires-form select[required]');

        this.local = this.element.querySelector('#currentLocal').value;
        this.siteId = this.element.querySelector('#siteId').value;
        this.endpoint = `/api/1.0/trelleborg/premium/warranty/create?siteId=${this.siteId}&locale=${this.local}`

        this.machineBrandEndopoint = `/api/1.0/trelleborg/premium/fleet/brand/${this.local}`;
        this.searchMachineBrand = this.element.querySelector('#machineBrand');
        this.suggestedMachineBrand = this.element.querySelector('#machineResults');


        this.init();
    }

    nextTabs() {
        this.next1.addEventListener('click', () => {
            this.tireSelected = this.tireSelection.options[this.tireSelection.selectedIndex].text;

            this.step1.classList.add('hidden');
            this.step2.classList.remove('hidden');

            this.rearFormInputs.forEach((rearInput) => {
                if (this.tireSelected === 'Front tires') {
                    this.rearForm.classList.add('hidden');
                    rearInput.removeAttribute("required");
                } else {
                    rearInput.setAttribute("required", "");
                }
            })

            this.frontFormInputs.forEach((frontInput) => {
                if (this.tireSelected === 'Rear tires') {
                    this.frontForm.classList.add('hidden');
                    frontInput.removeAttribute('required');
                } else {
                    frontInput.setAttribute("required", "");
                }
            })
        });

        this.next2.addEventListener('click', () => {
            this.step2.classList.add('hidden');
            this.step3.classList.remove('hidden');
        });
    }

    prevTabs() {
        this.prev2.addEventListener('click', () => {
            this.step1.classList.remove('hidden');
            this.step2.classList.add('hidden');
            this.rearForm.classList.remove('hidden');
            this.frontForm.classList.remove('hidden');
        });

        this.prev3.addEventListener('click', () => {
            this.step2.classList.remove('hidden');
            this.step3.classList.add('hidden');
        });
    }


    startBrandAutoComplete() {
        this.searchMachineBrand.addEventListener("input", () => {
            $('.search-icon').addClass('hidden');
            this.query = this.searchMachineBrand.value.toLowerCase();
            this.suggestedMachineBrand.innerHTML = ""; // Clear previous results
            if (!this.query) {
                return; // Stop if the input is empty
            }
            // Filter the suggestions based on input
            this.filteredSuggestions = this.machineBrands.filter(item => item.label.toLowerCase().includes(this.query));
            // Generate the dropdown list
            this.filteredSuggestions.forEach(suggestion => {
                const itemElement = document.createElement("div");
                itemElement.classList.add("autocomplete-item");
                itemElement.innerHTML = suggestion.label;
                // Add click event to set the input field to the clicked suggestion
                itemElement.addEventListener("click", () => {
                    this.searchMachineBrand.value = suggestion.label;
                    // this.machineBrandIdHidden.value = suggestion.value;
                    this.suggestedMachineBrand.innerHTML = ""; // Close the autocomplete list
                });
                this.suggestedMachineBrand.appendChild(itemElement);
            });
        });
        document.addEventListener("click", (e) => {
            if (!this.suggestedMachineBrand.contains(e.target) && e.target !== this.searchMachineBrand) {
                this.suggestedMachineBrand.innerHTML = "";
                $('.search-icon').removeClass('hidden');
            }
        });
    }

    getMachineBrands() {
        return youserxp.ajax(this.machineBrandEndopoint, {
            method: 'GET',
            auth: true
        }).then(
            (data) => {
                this.machineBrands = data.body;
                this.startBrandAutoComplete();
            },
            (err) => console.log(err)
        );
    }

    checkValidationForm() {
        event.preventDefault();

        let isValid = true;
        let firstInvalidField = null;

        this.inputs = this.element.querySelectorAll('input[required], select[required]');
        this.inputs.forEach(input => {
            if (!input.value.trim()) {
                isValid = false;
                if (!firstInvalidField) {
                    firstInvalidField = input;
                }
                input.classList.add('is-invalid');
                if (this.inputFile && this.inputFile.files.length === 0) {
                    this.fileNameDisplay.textContent = 'No file selected'
                }
            } else {
                input.classList.remove('is-invalid');
            }
        });

        if (!isValid) {
            // Switch to the tab containing the first invalid input
            const invalidTab = firstInvalidField.closest('.tab');
            const tabValue = invalidTab.getAttribute('id');

            this.tabs.forEach(tab => tab.classList.add('hidden'));
            this.element.querySelector(`[id="${tabValue}"]`).classList.remove('hidden');
            firstInvalidField.focus();
        }

        return isValid;
    }

    onChangeFile() {
        this.inputFile.addEventListener('change', (e) => {
            this.file = e.target.files[0]
            this.fileName = this.file.name;

            if (this.file) {
                this.fileNameDisplay.textContent = `File selected: ${this.fileName}`;
            } else {
                this.fileNameDisplay.textContent = 'No file selected'
            }
        })
    }


    ctaAddWarranty() {
        this.submit.addEventListener('click', () => {

            let machineBrandId = this.element.querySelector('#machineBrand')?.value;
            let machineModelId = this.element.querySelector('#machineModel').value;
            let dealer = this.element.querySelector('#dealer').value;
            let tiresExtendWarranty = this.element.querySelector('#tireWarranty').value;

            let frontTiresModelId = this.element.querySelector('#frontTireModel')?.value || null;
            let frontTiresSizeId = this.element.querySelector('#frontTiresSize')?.value || null;
            let productionDateFrontLeftTire = this.element.querySelector('#productionDateLeftTire')?.value || null
            let productionDateFrontRightTire = this.element.querySelector('#productionDateRightTire')?.value || null;
            let productionWeekFrontLeftTire = this.element.querySelector('#productionWeekFrontLeftTire')?.value || null;
            let productionWeekFrontRightTire = this.element.querySelector('#productionWeekFrontRightTire')?.value || null;
            let productionYearFrontLeftTire = this.element.querySelector('#productionYearFrontLeftTire')?.value || null;
            let productionYearFrontRightTire = this.element.querySelector('#productionYearFrontRightTire')?.value || null;

            let rearTiresModelId = this.element.querySelector('#rearTireModel')?.value || null;
            let rearTiresSizeId = this.element.querySelector('#rearTiresSize')?.value || null;
            let productionDateRearLeftTire = this.element.querySelector('#rearProductionDateLeftTire')?.value || null;
            let productionDateRearRightTire = this.element.querySelector('#rearProductionDateRightTire')?.value || null;
            let productionWeekRearLeftTire = this.element.querySelector('#productionWeekRearLeftTire')?.value || null;
            let productionWeekRearRightTire = this.element.querySelector('#productionWeekRearRightTire')?.value || null;
            let productionYearRearLeftTire = this.element.querySelector('#productionYearRearLeftTire')?.value || null;
            let productionYearRearRightTire = this.element.querySelector('#productionYearRearRightTire')?.value || null;

            let formData = new FormData();
            formData.append("file", this.file);
            formData.append("filename", this.fileName);
            formData.append("machineBrandId", machineBrandId);
            formData.append("machineModelId", machineModelId);
            formData.append("dealer", dealer);
            formData.append("tiresExtendWarranty", tiresExtendWarranty);

            if (tiresExtendWarranty == 1) {
                formData.append("frontTiresModelId", frontTiresModelId);
                formData.append("frontTiresSizeId", frontTiresSizeId);
                formData.append("productionDateFrontLeftTire", productionDateFrontLeftTire);
                formData.append("productionDateFrontRightTire", productionDateFrontRightTire);
                formData.append("productionWeekFrontLeftTire", productionWeekFrontLeftTire);
                formData.append("productionWeekFrontRightTire", productionWeekFrontRightTire);
                formData.append("productionYearFrontLeftTire", productionYearFrontLeftTire);
                formData.append("productionYearFrontRightTire", productionYearFrontRightTire);
            } else if (tiresExtendWarranty == 2) {
                formData.append("rearTiresModelId", rearTiresModelId);
                formData.append("rearTiresSizeId", rearTiresSizeId);
                formData.append("productionDateRearLeftTire", productionDateRearLeftTire);
                formData.append("productionDateRearRightTire", productionDateRearRightTire);
                formData.append("productionWeekRearLeftTire", productionWeekRearLeftTire);
                formData.append("productionWeekRearRightTire", productionWeekRearRightTire);
                formData.append("productionYearRearLeftTire", productionYearRearLeftTire);
                formData.append("productionYearRearRightTire", productionYearRearRightTire);
            } else if (tiresExtendWarranty == 3) {
                formData.append("frontTiresModelId", frontTiresModelId);
                formData.append("frontTiresSizeId", frontTiresSizeId);
                formData.append("productionDateFrontLeftTire", productionDateFrontLeftTire);
                formData.append("productionDateFrontRightTire", productionDateFrontRightTire);
                formData.append("productionWeekFrontLeftTire", productionWeekFrontLeftTire);
                formData.append("productionWeekFrontRightTire", productionWeekFrontRightTire);
                formData.append("productionYearFrontLeftTire", productionYearFrontLeftTire);
                formData.append("productionYearFrontRightTire", productionYearFrontRightTire);
                formData.append("rearTiresModelId", rearTiresModelId);
                formData.append("rearTiresSizeId", rearTiresSizeId);
                formData.append("productionDateRearLeftTire", productionDateRearLeftTire);
                formData.append("productionDateRearRightTire", productionDateRearRightTire);
                formData.append("productionWeekRearLeftTire", productionWeekRearLeftTire);
                formData.append("productionWeekRearRightTire", productionWeekRearRightTire);
                formData.append("productionYearRearLeftTire", productionYearRearLeftTire);
                formData.append("productionYearRearRightTire", productionYearRearRightTire);
            }
            this.submitWarranty(formData);
        })
    }


    submitWarranty(data) {
        if (this.checkValidationForm()) {
            this.element.classList.add('loading');
            return youserxp.ajax(this.endpoint, {
                method: 'POST',
                auth: true
            }, data, 'multipart/form-data').then(
                (result) => {
                    this.warranty = result.body;
                    this.redirectToWarranties();
                    this.element.classList.remove('loading');
                },
                (err) => {
                    console.error('Error creating warranty:', err);
                    this.element.classList.remove('loading');
                }
            );
        }
    }

    redirectToWarranties() {
        let currentUrl = window.location.href;

        if (currentUrl.includes('/extended-warranty')) {
            let newUrl = currentUrl.replace('/extended-warranty', '/my-warranties-listing');
            window.location.href = newUrl;
        }
    }


    init() {
        this.nextTabs();
        this.prevTabs();
        this.getMachineBrands();
        this.ctaAddWarranty();
        this.onChangeFile();
    }
}

export default ExtendedWarrantyComponent;
