import {SmartComponent} from "smart-component-js";


class DatePickerComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element
        this.init();
    }


    init() {
        
        $('input[name="date"]').flatpickr({
            dateFormat: "d-m-Y",
        });
    }
}

export default DatePickerComponent;
