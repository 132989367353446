import {SmartComponent} from "smart-component-js";


class DeleteFleetComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;

        this.init();
    }

    deleteCtaListener() {
        this.deleteCta = this.element.querySelectorAll('.fleet-card__delete');
        this.deleteCta.forEach((cta) => {
            cta.addEventListener('click', () => {
                var entityId = cta.id
                const userConfirmed = confirm('Are you sure you want to delete this item?');

                if (userConfirmed) {
                    this.deleteFleet(entityId);
                    this.element.classList.add('loading');
                } else {
                    alert('Delete action was canceled.');
                }
            })
        })
    }

    deleteFleet(entityId) {
        return youserxp.ajax(`/api/1.0/trelleborg/premium/fleet/${entityId}`, {
            method: 'DELETE',
            auth: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
                alert('Fleet successfully deleted.');
                location.reload();
                this.element.classList.remove('loading');
            },
            (err) => {
                console.error('Error deleting fleet:', err);
            }
        )

    }


    init() {
        this.deleteCtaListener();
    }
}

export default DeleteFleetComponent;
