import {SmartComponent} from "smart-component-js";


class TelInputComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element
        this.init();
    }


    init() {
        const input = document.querySelector("#phone");
        window.intlTelInput(input, {
            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.5.0/build/js/utils.js",
            formatOnDisplay: false,
            nationalMode: false,
            autoPlaceholder: 'polite',
            separateDialCode: true,
            formatAsYouType: false,
            initialCountry: "auto",
            geoIpLookup: function (success, failure) {
                fetch("https://ipapi.co/json")
                    .then(function (res) { return res.json(); })
                    .then(function (data) { success(data.country_code); })
                    .catch(function () { failure(); });
            }
        });
    }
}

export default TelInputComponent;
