import $ from "jquery";
import {SmartComponentManager} from "smart-component-js";
import DefaultCtrl from "./controllers/DefaultCtrl";
import BannerFullWidthComponent from "./components/BannerFullWidthComponent";
import CardWideComponent from "./components/CardWideComponent";
import RegistrationComponent from "./components/RegistrationComponent";
import ContactComponent from "./components/ContactComponent";
import CustomFormComponent from "./components/CustomFormComponent";
import RegionAccordionComponent from "./components/RegionAccordionComponent";
import VisualComponent from "./components/VisualComponent";
import GenericSliderComponent from "./components/GenericSliderComponent";
import HeaderComponent from "./components/HeaderComponent";
import ProfileComponent from "./components/ProfileComponent";
import DatePickerComponent from "./components/DatePickerComponent";
import FleetComponent from "./components/FleetComponent";
import ExtendedWarrantyComponent from "./components/ExtendedWarrantyComponent";
import ContactFormComponent from "./components/ContactFormComponent";
import TelInputComponent from "./components/TelInputComponent";
import CompleteRegistrationComponent from "./components/CompleteRegistrationComponent";
import CommunicationComponent from "./components/CommunicationComponent";
import DeleteFleetComponent from "./components/DeleteFleetComponent";


import "../../styles/trelleborg/trelleborg.scss";


SmartComponentManager.registerComponents({
    DefaultCtrl,
    BannerFullWidthComponent,
    CardWideComponent,
    RegistrationComponent,
    ContactComponent,
    CustomFormComponent,
    RegionAccordionComponent,
    VisualComponent,
    GenericSliderComponent,
    HeaderComponent,
    ProfileComponent,
    DatePickerComponent,
    FleetComponent,
    ExtendedWarrantyComponent,
    ContactFormComponent,
    TelInputComponent,
    CompleteRegistrationComponent,
    CommunicationComponent,
    DeleteFleetComponent,
});

$(document).ready(() => {
    let $body = $('body[component], .bodySurrogate[component], div[component="DefaultCtrl"]');
    if ($body.length > 0) {
        SmartComponentManager.initComponentByName($body[0], $body.attr('component'));
    } else {

    }
});

