import {SmartComponent} from "smart-component-js";


class ContactFormComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.submit = this.element.querySelector('.send-message');
        this.contactForm = this.element.querySelector('.form-contact');
        this.thankyou = this.element.querySelector('.thankyou-contact');

        this.init();
    }


    checkValidationForm() {
        event.preventDefault();

        let isValid = true;
        let firstInvalidField = null;

        this.inputs = this.element.querySelectorAll('input[required], textarea[required]');
        this.inputs.forEach(input => {
            if (!input.value.trim()) {
                isValid = false;
                if (!firstInvalidField) {
                    firstInvalidField = input;
                }
                input.classList.add('is-invalid');
            } else {
                input.classList.remove('is-invalid');
            }
        });

        if (!isValid) {
            // Switch to the tab containing the first invalid input

            firstInvalidField.focus();
        }

        return isValid;
    }


    sendMessageCtaListener() {
        this.submit.addEventListener('click', () => {

            let userId = this.element.querySelector('#id').value;
            let subject = this.element.querySelector('#subject').value;
            let text = this.element.querySelector('#message').value;
            let currentLocal = this.element.querySelector('#currentLocal').value;

            const userMessage = {
                userId: userId,
                subject: subject,
                text: text,
                currentLocal: currentLocal
            }
            this.sendUserMessage(userMessage);

        })
    }

    sendUserMessage(data) {

        if (this.checkValidationForm()) {
            this.element.classList.add('loading');

            return youserxp.ajax('/api/1.0/trelleborg/premium/mycontact', {
                method: 'POST',
                auth: true,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(
                (data) => {
                    this.message = data.body
                    this.contactForm.classList.add('hidden');
                    this.thankyou.classList.remove('hidden');
                    this.element.classList.remove('loading');
                },
                (err) => {
                    console.error('Error sending message:', err);
                    if (err.message) {
                        console.log(err.message)
                    }
                    this.element.classList.remove('loading');
                }
            )
        }
    }


    init() {
        this.sendMessageCtaListener();
    }
}

export default ContactFormComponent;
