import {SmartComponent} from "smart-component-js";


class FleetComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;

        this.local = this.element.querySelector('#currentLocal').value;

        this.machineBrandEndopoint = `/api/1.0/trelleborg/premium/fleet/brand/${this.local}`;
        this.createEndpoint = '/api/1.0/trelleborg/premium/fleet';

        this.param = new URLSearchParams(window.location.search);
        this.entityId = this.param.get('entityId');
        this.editEndpoint = `/api/1.0/trelleborg/premium/fleet/${this.entityId}`

        this.searchMachineBrand = this.element.querySelector('#machineBrandId');
        this.searchMachineModel = this.element.querySelector('#machineModelId');
        this.suggestedMachineBrand = this.element.querySelector('#machineBrandResults');
        this.suggestedMachineModel = this.element.querySelector('#machineModelResults');
        this.machineBrandIdHidden = this.element.querySelector('#machineBrandIdHidden');

        this.init();
    }


    startBrandAutoComplete() {
        this.searchMachineBrand.addEventListener("input", () => {
            $('.search-icon').addClass('hidden');
            this.query = this.searchMachineBrand.value.toLowerCase();
            this.suggestedMachineBrand.innerHTML = ""; // Clear previous results
            if (!this.query) {
                return; // Stop if the input is empty
            }
            // Filter the suggestions based on input
            this.filteredSuggestions = this.machineBrands.filter(item => item.label.toLowerCase().includes(this.query));
            // Generate the dropdown list
            this.filteredSuggestions.forEach(suggestion => {
                const itemElement = document.createElement("div");
                itemElement.classList.add("autocomplete-item");
                itemElement.innerHTML = suggestion.label;
                // Add click event to set the input field to the clicked suggestion
                itemElement.addEventListener("click", () => {
                    this.searchMachineBrand.value = suggestion.label;
                    this.machineBrandIdHidden.value = suggestion.value;
                    this.suggestedMachineBrand.innerHTML = ""; // Close the autocomplete list
                });
                this.suggestedMachineBrand.appendChild(itemElement);
            });
        });
        document.addEventListener("click", (e) => {
            if (!this.suggestedMachineBrand.contains(e.target) && e.target !== this.searchMachineBrand) {
                this.suggestedMachineBrand.innerHTML = "";
                $('.search-icon').removeClass('hidden');
            }
        });
    }

    setMachineBrand() {
        this.machineBrandId = this.machineBrandIdHidden.value;
        if (!this.machineBrandId) {
            return;
        }
        this.machineBrandLabel = this.machineBrands.find((el) => el.value === this.machineBrandId).label;
        this.searchMachineBrand.value = this.machineBrandLabel;
    }

    getMachineBrands() {
        return youserxp.ajax(this.machineBrandEndopoint, {
            method: 'GET',
            auth: true
        }).then(
            (data) => {
                this.machineBrands = data.body;
                this.setMachineBrand();
                this.startBrandAutoComplete();
            },
            (err) => console.log(err)
        );
    }

    checkValidationForm() {
        event.preventDefault();
        let isValid = true;
        let firstInvalidField = null;
        this.inputs = this.element.querySelectorAll('input[required], select[required]');
        this.inputs.forEach(input => {
            if (!input.value.trim()) {
                isValid = false;
                if (!firstInvalidField) {
                    firstInvalidField = input;
                }
                input.classList.add('is-invalid');
            } else {
                input.classList.remove('is-invalid');
            }
        });
        if (firstInvalidField) {
            firstInvalidField.focus();
        }
        return isValid;
    }


    getFleetData() {
        const id = this.element.querySelector('#id')?.value || null;
        const machineBrandId = this.machineBrandIdHidden.value;
        const machineModelId = this.element.querySelector('#machineModelId').value;
        const machinePurchaseYear = this.element.querySelector('#machinePurchaseYear').value;
        const frontTireSize = this.element.querySelector('#frontTireSize').value;
        const rearTireSize = this.element.querySelector('#rearTireSize').value;
        const tireBrandModelId = this.element.querySelector('#tireBrandModelId').value;
        const tirePurchaseYear = this.element.querySelector('#tirePurchaseYear').value;

        return {
            id: id,
            machineBrandId: machineBrandId,
            machineModelId: machineModelId,
            machinePurchaseYear: machinePurchaseYear,
            frontTireSize: frontTireSize,
            rearTireSize: rearTireSize,
            tireBrandModelId: tireBrandModelId,
            tirePurchaseYear: tirePurchaseYear
        };
    }

    ctaAddFleet() {
        this.addCta = this.element.querySelector('.submit-fleet');
        if (this.addCta) {
            this.addCta.addEventListener('click', () => {
                const data = this.getFleetData();
                this.createFleet(data);
            });
        }
    }

    ctaEditFleet() {
        this.editCta = this.element.querySelector('.edit-fleet');
        if (this.editCta) {
            this.editCta.addEventListener('click', () => {
                const editData = this.getFleetData();
                this.editFleet(editData);
            });
        }
    }


    createFleet(data) {
        if (this.checkValidationForm()) {
            this.element.classList.add('loading');
            return youserxp.ajax(this.createEndpoint, {
                method: 'POST',
                auth: true,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(
                (result) => {
                    this.addFleet = result.body;
                    this.redirectToMyFleet();
                    this.element.classList.remove('loading');

                },
                (err) => {
                    console.error('Error creating fleet:', err);
                    this.element.classList.remove('loading');
                }
            );
        }

    }

    editFleet(data) {
        if (this.checkValidationForm()) {
            this.element.classList.add('loading');
            return youserxp.ajax(this.editEndpoint, {
                method: 'PUT',
                auth: true,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(
                (result) => {
                    this.modifiedFleet = result.body;
                    this.redirectToMyFleet();
                    this.element.classList.remove('loading');
                },
                (err) => {
                    console.error('Error creating fleet:', err)
                }
            )
        }
    }


    redirectToMyFleet() {
        let currentUrl = window.location.href;

        if (currentUrl.includes('/my-fleet-create-or-update')) {
            let newUrl = currentUrl.split('/my-fleet-create-or-update')[0] + '/my-fleet';
            window.location.href = newUrl;
        }
    }


    init() {
        this.getMachineBrands();
        this.ctaAddFleet();
        this.ctaEditFleet();
    }
}

export default FleetComponent;