import {SmartComponent} from "smart-component-js";
import Swiper, {Pagination, Navigation, Autoplay} from 'swiper';

class HeaderComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element

        this.init();
    }


    init() {
        $('.hamburger').click(() => {
            $('.header-premium').toggleClass('open');
        })

        $('.user').click(() => {
            $('.inner-menu').toggleClass('open');
            $('.profile-arrow').toggleClass('open');
        })

    }


}

export default HeaderComponent;
