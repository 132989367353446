import {SmartComponent} from "smart-component-js";


class CommunicationComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.loadMore = this.element.querySelector('#loadMoreButton');
        this.init();
    }


    loadMoreMessages() {
        this.loadMore.addEventListener('click', () => {
            this.hiddenCards = this.element.querySelectorAll('.communications__card[style*="display:none"]');

            for (let i = 0; i < Math.min(this.hiddenCards.length, 5); i++) {
                this.hiddenCards[i].style.display = 'block';
            }

            if (this.hiddenCards.length <= 5) {
                this.style.display = 'none';
            }
        });
    }


    init() {
        this.loadMoreMessages();
    }

}

export default CommunicationComponent;
