import {SmartComponent} from "smart-component-js";


class ProfileComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.endpoint = '/api/1.0/trelleborg/premium/profile/';
        this.submit = this.element.querySelectorAll('.submit-profile');
        this.tabs = this.element.querySelectorAll('.tablinks');
        this.tabInfos = this.element.querySelectorAll('.tabs__tab');
        this.form = this.element.querySelector('#myProfile');
        this.init();
    }


    selectTabs() {
        this.tabs.forEach(tab => {
            tab.addEventListener('click', () => {
                $('.tablinks.active').removeClass('active');
                tab.classList.add('active');

                this.tabInfos.forEach(info => {
                    if (tab.dataset.tabValue === info.dataset.tabInfo) {
                        info.classList.add('active');
                    } else {
                        info.classList.remove('active');
                    }
                })
            })
        })
    }

    submitProfile() {
        this.submit.forEach((cta) => {
            cta.addEventListener('click', () => {

                let id = this.element.querySelector('#id').value;
                let name = this.element.querySelector('#name').value;
                let surname = this.element.querySelector('#surname').value;
                let email = this.element.querySelector('#email').value;
                let phone = this.element.querySelector('#phone').value;
                let birthday = this.element.querySelector('#birthday').value;
                let companyName = this.element.querySelector('#companyName').value;
                let street1 = this.element.querySelector('#street1').value;
                let stateProvince = this.element.querySelector('#stateProvince').value;
                let street2 = this.element.querySelector('#street2').value;
                let zipCode = this.element.querySelector('#zip').value;
                let street3 = this.element.querySelector('#street3').value;
                let city = this.element.querySelector('#city').value;
                let country = this.element.querySelector('#country').value;
                let mainBusinessId = this.element.querySelector('#mainBusiness').value;
                let numberOfTractorsId = this.element.querySelector('#numberOfTractors').value;
                let farmSizeId = this.element.querySelector('#farmSize').value;
                let numberOfEmployeesId = this.element.querySelector('#numberOfEmployees').value || null;
                let preferredTireDealerId = this.element.querySelector('#preferredTireDealer').value;
                let preferredMachineDealerId = this.element.querySelector('#preferredMachineDealer').value || null;
                let preferredTireBrandsNewMachineId = this.element.querySelector('#preferredTireBrandsNewMachine').value || null;
                let preferredTireBrandsReplacementId = this.element.querySelector('#preferredTireBrandsReplacement').value || null;
                let usualTimeBeforeChangingTiresId = this.element.querySelector('#usualTimeBeforeChangingTires').value || null;
                let usualTimeBeforeChangingMachineryId = this.element.querySelector('#usualTimeBeforeChangingMachinery').value || null;


                const profileData = {
                    id: id,
                    name: name,
                    surname: surname,
                    email: email,
                    phone: phone,
                    birthday: birthday,
                    companyName: companyName,
                    street1: street1,
                    stateProvince: stateProvince,
                    street2: street2,
                    zip: zipCode,
                    street3: street3,
                    city: city,
                    country: country,
                    mainBusinessId: mainBusinessId,
                    numberOfTractorsId: numberOfTractorsId,
                    farmSizeId: farmSizeId,
                    numberOfEmployeesId: numberOfEmployeesId,
                    preferredTireDealerId: preferredTireDealerId,
                    preferredMachineDealerId: preferredMachineDealerId,
                    preferredTireBrandsNewMachineId: preferredTireBrandsNewMachineId,
                    preferredTireBrandsReplacementId: preferredTireBrandsReplacementId,
                    usualTimeBeforeChangingTiresId: usualTimeBeforeChangingTiresId,
                    usualTimeBeforeChangingMachineryId: usualTimeBeforeChangingMachineryId
                }

                console.log(profileData)
                this.updateProfile(profileData);
            })
        })
    }


    updateProfile(data) {
        if (this.checkValidationForm()) {
            this.element.classList.add('loading');

            return youserxp.ajax(`/api/1.0/trelleborg/premium/profile`, {
                method: 'PUT',
                auth: true,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(
                (data) => {
                    this.data = data.body
                    location.reload();

                },
                (err) => {
                    console.error('Error submitting profile:', err);
                    if (err.message) {
                        console.log(err.message)
                    }
                    this.element.classList.remove('loading');
                }
            )
        }
    }

    checkValidationForm() {
        event.preventDefault();

        let isValid = true;
        let firstInvalidField = null;

        this.inputs = this.element.querySelectorAll('input[required], select[required]');
        this.inputs.forEach(input => {
            if (!input.value.trim()) {
                isValid = false;
                if (!firstInvalidField) {
                    firstInvalidField = input;
                }
                input.classList.add('is-invalid');
            } else {
                input.classList.remove('is-invalid');
            }
        });

        if (!isValid) {
            // Switch to the tab containing the first invalid input
            const invalidTab = firstInvalidField.closest('.tabs__tab');
            const tabValue = invalidTab.getAttribute('data-tab-info');

            this.tabInfos.forEach(tab => tab.classList.remove('active'));
            this.element.querySelector(`[data-tab-info="${tabValue}"]`).classList.add('active');

            this.element.querySelectorAll('.tablinks').forEach(tabLink => tabLink.classList.remove('active'));
            this.element.querySelector(`.tablinks[data-tab-value="${tabValue}"]`).classList.add('active');

            firstInvalidField.focus();
        }

        return isValid;
    }


    // redirectToDashboard() {
    //     let currentUrl = window.location.href;
    //
    //     if (currentUrl.includes('/my-profile')) {
    //         let newUrl = currentUrl.replace('/my-profile', '/dashboard');
    //         window.location.href = newUrl;
    //     }
    // }

    init() {
        this.selectTabs();
        this.submitProfile();

    }
}

export default ProfileComponent;
