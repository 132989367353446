import {SmartComponent} from "smart-component-js";


class CompleteRegistrationComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.endpoint = '/api/1.0/trelleborg/premium/registration/completation/';
        this.submit = this.element.querySelector('.submit-registration');
        this.init();
    }

    checkValidationForm() {
        event.preventDefault();
        let isValid = true;
        let firstInvalidField = null;
        this.inputs = this.element.querySelectorAll('input[required]');
        this.inputs.forEach(input => {
            if (!input.value.trim()) {
                isValid = false;
                if (!firstInvalidField) {
                    firstInvalidField = input;
                }
                input.classList.add('is-invalid');
            } else {
                input.classList.remove('is-invalid');
            }
        });
        if (firstInvalidField) {
            firstInvalidField.focus();
        }
        return isValid;
    }


    submitUserRegistration() {
        this.submit.addEventListener('click', () => {

            let id = this.element.querySelector('#id').value;
            let name = this.element.querySelector('#name').value;
            let surname = this.element.querySelector('#surname').value;
            let phone = this.element.querySelector('#phone').value;
            let companyName = this.element.querySelector('#companyName').value;
            let city = this.element.querySelector('#city').value;
            let province = this.element.querySelector('#state').value;
            let country = this.element.querySelector('#country').value;
            let mainActivity = this.element.querySelector('#mainActivity').value;
            let numberOfTractors = this.element.querySelector('#tractorsNum').value;
            let farmSurface = this.element.querySelector('#farmSurface').value;

            const contactData = {
                id: id,
                name: name,
                surname: surname,
                phone: phone,
                companyName: companyName,
                city: city,
                province: province,
                country: country,
                mainActivity: mainActivity,
                numberOfTractors: numberOfTractors,
                farmSurface: farmSurface
            }
            this.createUserContact(contactData);

        })

    }


    createUserContact(data) {
        if (this.checkValidationForm()) {
            this.element.classList.add('loading');
            return youserxp.ajax(`/api/1.0/trelleborg/premium/registration/completation`, {
                method: 'POST',
                auth: true,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(
                (data) => {
                    this.data = data;
                    this.redirectToDashboard();
                    this.element.classList.remove('loading');
                },
                (err) => {
                    console.error('Error completing registration:', err);
                    this.element.classList.remove('loading');
                }
            );
        }
    }

    redirectToDashboard() {
        let currentUrl = window.location.href;

        if (currentUrl.includes('/registration-completation')) {
            let newUrl = currentUrl.split('/registration-completation')[0] + '/dashboard';
            window.location.href = newUrl;
        }
    }

    init() {
        this.submitUserRegistration();
    }
}

export default CompleteRegistrationComponent;
